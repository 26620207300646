import Color from 'colorjs.io'

export const tint = (amount: number, color: string) => {
  const colorObj = new Color(color)
  return colorObj
    .mix(new Color('white'), amount)
    .to('srgb')
    .toString({ format: 'hex' })
}

export const shade = (amount: number, color: string) => {
  const colorObj = new Color(color)
  return colorObj
    .mix(new Color('black'), amount)
    .to('srgb')
    .toString({ format: 'hex' })
}

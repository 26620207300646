import React, { createContext, ReactNode, useContext, useState } from 'react'

interface ThemeContextType {
  darkMode: boolean
  orderingRevampEnabled: boolean
  toggleTheme: () => void
}

export const ThemeContext = createContext<ThemeContextType>({
  darkMode: false,
  orderingRevampEnabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleTheme: () => {},
})

interface ThemeProviderProps {
  newUIEnabled: boolean
  children: ReactNode
}

export const ThemeProvider = ({
  newUIEnabled,
  children,
}: ThemeProviderProps) => {
  const [orderingRevampEnabled] = useState(newUIEnabled)
  const [darkMode, setDarkMode] = useState(false)

  const toggleTheme = () => {
    setDarkMode((prevMode) => !prevMode)
  }

  return (
    <ThemeContext.Provider
      value={{ darkMode, orderingRevampEnabled, toggleTheme }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)

import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { evaluateFlags, flagsClient, getDefinitions } from '@unleash/nextjs'
import NodeCache from 'node-cache'

import { ENABLE_ORDERING_REVAMP } from '@/common/constants'
import { getEnv } from '@/lib/env'

const cache = new NodeCache({
  maxKeys: 5000,
  stdTTL: 3600,
  checkperiod: 30,
})

export async function getOrderingRevampEnabled(venueSlug: string) {
  if (!isServer) {
    return null
  }

  try {
    const cacheKey = `orderingRevampEnabled.${venueSlug}`
    const cachedFlag = cache.get<boolean>(cacheKey)

    if (typeof cachedFlag === 'boolean') {
      return cachedFlag
    }

    const env = getEnv()

    const definitions = await getDefinitions()

    const { toggles } = evaluateFlags(definitions, {
      properties: { venueSlug },
      appName: 'serve-frontend',
      environment: env.APP_ENV,
    })

    const flags = flagsClient(toggles)
    const enabled = flags.isEnabled(ENABLE_ORDERING_REVAMP)
    cache.set(cacheKey, enabled)

    return enabled
  } catch {
    return false
  }
}

import { Elements } from '@stripe/react-stripe-js'
import React, { PropsWithChildren, useMemo } from 'react'

import { getStripeInstance } from '@/utils/stripe'

type StripeContextProviderProps = {
  currency: string
}

export const StripeContextProvider = ({
  children,
  currency,
}: PropsWithChildren<StripeContextProviderProps>) => {
  const stripe = useMemo(() => getStripeInstance(currency), [currency])

  return (
    <Elements key={currency} stripe={stripe}>
      {children}
    </Elements>
  )
}

import Script from 'next/script'
import React from 'react'

import { GuestVenue, Maybe } from '@/gql/graphql'

import { getOrCreateExternalId } from './VisitorsTracking'

export const FacebookTracking = ({
  guestVenue,
}: {
  guestVenue?: Maybe<Pick<GuestVenue, 'facebookPixelId'>>
}) => {
  const venueFacebookPixelId = guestVenue?.facebookPixelId
  const externalId = getOrCreateExternalId()

  const userData = externalId ? `, {external_id: "${externalId}"}` : ''

  const venuePixel = venueFacebookPixelId
    ? `fbq("init", "${venueFacebookPixelId}"${userData});`
    : ''

  return (
    <>
      <Script
        id="facebookPixel"
        key="venueFacebookPixel"
        dangerouslySetInnerHTML={{
          __html: `
      if (typeof fbq === 'undefined') {
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "929702950410474"${userData});
        ${venuePixel}
        fbq("track", "PageView");
      }
        `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=929702950410474&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  )
}

import Bugsnag from '@bugsnag/js'
import BugsnagReact from '@bugsnag/plugin-react'

import { Environment } from '@/lib/env'

export const initBugsnag = (
  apiKey: string,
  releaseStage: Environment,
  appVersion?: string,
) => {
  // @ts-expect-error: Private property _client
  if (process.env.NODE_ENV === 'test' || Bugsnag._client) return
  Bugsnag.start({
    apiKey,
    releaseStage,
    appVersion,
    plugins: [new BugsnagReact()],
  })
}

export const reportError = (error: Error) => {
  Bugsnag.notify(error)
}

export const reportErrorContext = (error: Error, context: string) => {
  Bugsnag.notify(error, (event) => {
    ;(event as any).context = context
  })
}

import { useRouter } from 'next/router'
import React from 'react'

import { GuestVenue, Maybe } from '@/gql/graphql'

interface Props {
  googleTagManagerId?: string
}

export const GTMIframe = ({ googleTagManagerId }: Props) => {
  if (!googleTagManagerId) return null
  return (
    <noscript>
      <iframe
        key={`iframe-${googleTagManagerId}`}
        src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  )
}

export const GoogleTagManagerIframe = ({
  guestVenue,
}: {
  guestVenue?: Maybe<Pick<GuestVenue, 'googleTagManagerId'>>
}) => {
  const router = useRouter()

  let googleTagManagerId = guestVenue?.googleTagManagerId

  if (router.asPath.match(/^\/discover\/syd/)) {
    googleTagManagerId = 'GTM-M85ZCBW'
  } else if (router.asPath.match(/^\/discover\/perthairport/)) {
    googleTagManagerId = 'GTM-KXCBGDS'
  }

  return <GTMIframe googleTagManagerId={googleTagManagerId!} />
}

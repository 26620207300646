import { resizes, TransformType } from '@mr-yum/cdn-image'

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src
}

const calculateImageHeight = (
  cropWidth: number,
  cropHeight: number,
  resizedWidth: number,
) => {
  if (cropWidth > cropHeight) {
    return Math.floor((cropHeight / cropWidth) * resizedWidth)
  }

  return Math.floor((resizedWidth / cropWidth) * cropHeight)
}

export const cloudflareImageUrl = (
  cloudflareImageResizingUrl: string,
  {
    src,
    width,
    quality = 75,
    transform = TransformType.DEFAULT,
  }: {
    src: string
    width: number
    quality?: number
    transform?: TransformType
  },
) => {
  const sizes = resizes[transform]
  const params = [`width=${width}`, `quality=${quality}`]

  if (sizes) {
    const [imageSize] = sizes

    if (imageSize.height) {
      params.push(
        `height=${calculateImageHeight(
          imageSize.width,
          imageSize.height,
          width,
        )},fit=cover`,
      )
    }
  }

  const paramsString = params.filter(Boolean).join(',')

  return `${cloudflareImageResizingUrl}/${paramsString},format=auto/${normalizeSrc(src)}`
}

import {
  flattenTokens,
  generateCustomThemeVariables,
  tokens,
} from '@mr-yum/frontend-ui'
import Color from 'colorjs.io'
import React from 'react'

import { useTheme } from '@/contexts/ThemeContext'
import { BrandingColors } from '@/hooks/useVenueBrandingColors'

import { shade } from './utils'

interface Props {
  colors: BrandingColors
  hasCustomBranding: boolean
}

function getTextColorHSL(background: Color) {
  const white = new Color('hsl', [0, 0, 100])

  if (Math.abs(white.contrastAPCA(background)) < 40) {
    const [h, s, l] = background.coords
    return new Color('hsl', [h, Math.max(s * 0.4, 10), Math.max(l * 0.2, 15)])
  }

  return white
}

export const ThemeOverrides = ({ colors, hasCustomBranding }: Props) => {
  const { orderingRevampEnabled } = useTheme()

  if (!orderingRevampEnabled) {
    return null
  }

  if (!hasCustomBranding) {
    return (
      <style>
        {`
        :root {
          --foreground-ui-navbar: #0D0D0D;
          --surface-ui-navbar: #ffffff;
          --surface-desktop: #FFFAF3;
        }
        `}
      </style>
    )
  }

  const accent = new Color(colors.accentBrandingColor).to('hsl')
  const white = new Color('#ffffff')
  const black = new Color('#181819')

  const contrastWhite = Math.abs(white.contrastAPCA(accent))
  const contrastBlack = Math.abs(black.contrastAPCA(accent))

  let textColor = contrastWhite > contrastBlack ? white : black

  if (contrastWhite < 40 && contrastBlack < 40) {
    textColor = getTextColorHSL(accent)
  }

  const [h] = accent.coords

  const accentLight = new Color('hsl', [h, 39, 91])
    .to('srgb')
    .toString({ format: 'hex' })
  const accentMedium = new Color('hsl', [h, 44, 83])
    .to('srgb')
    .toString({ format: 'hex' })
  const accentDark = new Color('hsl', [h, 82, 20])
    .to('srgb')
    .toString({ format: 'hex' })
  const contrastText = textColor.to('srgb').toString({ format: 'hex' })

  const updatedTokens = {
    ...tokens,
    color: {
      ...tokens.color,
      surface: {
        ...tokens.color.surface,
        'ui-navbar': {
          light: '#ffffff',
          dark: '#ffffff',
        },
        desktop: {
          light: accentLight,
          dark: accentLight,
        },
      },
      foreground: {
        ...tokens.color.foreground,
        'ui-navbar': {
          light: '#0D0D0D',
          dark: '#0D0D0D',
        },
      },
      action: {
        ...tokens.color.action,
        primary: {
          foreground: {
            light: contrastText,
            dark: contrastText,
          },
          surface: {
            light: colors.accentBrandingColor,
            dark: colors.accentBrandingColor,
          },
          'surface-hover': {
            light: shade(0.1, colors.accentBrandingColor),
            dark: shade(0.1, colors.accentBrandingColor),
          },
        },
        tonal: {
          foreground: {
            light: accentDark,
            dark: accentDark,
          },
          surface: {
            light: accentLight,
            dark: accentLight,
          },
          'surface-hover': {
            light: accentMedium,
            dark: accentMedium,
          },
        },
        'ghost-primary': {
          foreground: {
            light: accentDark,
            dark: accentDark,
          },
          'surface-hover': {
            light: accentLight,
            dark: accentLight,
          },
        },
        'text-primary': {
          foreground: {
            light: accentDark,
            dark: accentDark,
          },
        },
        'text-secondary': {
          foreground: {
            light: '#181819',
            dark: '#181819',
          },
        },
      },
    },
  }

  const flattenedTokens = flattenTokens(updatedTokens.color)
  const variables = generateCustomThemeVariables(flattenedTokens)

  return (
    <style>
      {`
      :root {
        ${variables[':root']}
      }
      .dark {
        ${variables['.dark']}
      }
    `}
    </style>
  )
}

import React from 'react'
import { useQuery } from 'urql'

import { VenueMetaImageDocument } from '@/gql/graphql'
import { config } from '@/lib/config'
import { cloudflareImageUrl } from '@/utils/cloudflareImageUrl'

import { Meta } from './Meta'

interface Props {
  venueSlug: string | null
}

export const VenueMetaImage = ({ venueSlug }: Props) => {
  const [{ data }] = useQuery({
    query: VenueMetaImageDocument,
    variables: {
      venueSlug: venueSlug || '',
    },
    pause: !venueSlug,
  })

  if (data?.guestVenue?.image) {
    const imageUrl = cloudflareImageUrl(config.cloudflareImageResizingUrl, {
      src: data.guestVenue.image.originalImageUrl,
      width: 1000,
    })

    return (
      <Meta
        openGraph={{
          images: [
            {
              url: imageUrl,
              width: 1200,
              height: 627,
              alt: 'Order and pay',
            },
          ],
        }}
      />
    )
  }

  return (
    <Meta
      openGraph={{
        images: [
          {
            url: '/static/opengraph-image.jpg',
            width: 1200,
            height: 630,
            alt: 'Order and pay',
          },
        ],
      }}
    />
  )
}

import { parseCookies } from 'nookies'
import { useEffect, useState } from 'react'

import { config } from '@/lib/config'
import { ORIGIN_COOKIE } from '@/lib/utils'

let cookies = parseCookies() || {}

export const isLokeOrigin = () => {
  const origin = (cookies[ORIGIN_COOKIE] || '').toLowerCase()
  const isOriginLoke = origin === 'loke'
  return isOriginLoke
}

export const updateLokeEnvironment = () => {
  cookies = parseCookies() || {}
}

export const useLoke = (hasLoke?: boolean) => {
  const loadLoke = isLokeOrigin() && (hasLoke == null || hasLoke === true)
  const [tidy, setTidy] = useState<any>()

  useEffect(() => {
    const timer = setInterval(() => {
      const anyWindow = window as any

      if (anyWindow.Tidy) {
        const tidyInstance = new anyWindow.Tidy({
          clientId: config.lokeClientId,
        })
        setTidy(tidyInstance)
        clearInterval(timer)
      }
    }, 100)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return { tidy, isLoke: loadLoke }
}

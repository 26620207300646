import { createContext, useContext } from 'react'

interface EonxEnvContextProps {
  isEonxEnv: boolean | null
  enableEonx: () => void
  disableEonx: () => void
}

export const EonxEnvContext = createContext<EonxEnvContextProps>({
  isEonxEnv: false,
  enableEonx: () => false,
  disableEonx: () => false,
})

export const useEonxEnvContext = () => {
  return useContext(EonxEnvContext)
}

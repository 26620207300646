import { z } from 'zod'

const environments = ['development', 'staging', 'production'] as const

export type Environment = (typeof environments)[number]

const envSchema = z.object({
  APP_ENV: z.enum(environments),
  APP_VERSION: z.string().min(1).optional(),
  BUGSNAG_KEY: z.string().length(32),
  GA_TRACKING_ID: z.string().min(1),
  ORIGIN_COOKIE: z.enum(['mryum.origin', 'staging-mryum.origin']),
  API_URL: z.string().url(),
  API_PRIVATE_URL: z.string().url().optional(),
  BASE_URL: z.string().url(),
  CLOUDFLARE_IMAGE_RESIZING_URL: z.string().url(),
  REGION: z.enum(['ap1', 'eu1', 'us1', 'dev']),
  STRIPE_KEY_AUD: z.string().min(1),
  STRIPE_KEY_NZD: z.string().min(1),
  STRIPE_KEY_USD: z.string().min(1),
  STRIPE_KEY_SGD: z.string().min(1),
  STRIPE_KEY_GBP: z.string().min(1),
  UNLEASH_SERVER_API_URL: z.string().url(),
  UNLEASH_SERVER_API_TOKEN: z.string().min(1),
  GOOGLE_TAG_MANAGER_ID_SYD: z.string().min(1),
  GOOGLE_TAG_MANAGER_ID_PER: z.string().min(1),
  AMPLITUDE_KEY: z.string().min(1),
})

export type Env = z.infer<typeof envSchema>

let env: Env | undefined

export const getEnv = () => {
  if (env) return env
  env = envSchema.parse(process.env)
  return env
}

import Cookies from 'js-cookie'
import { parseCookies } from 'nookies'
import React, { ReactNode, useEffect, useState } from 'react'

import { EonxEnvContext } from '@/contexts/EonxEnvContext'
import { ORIGIN_COOKIE } from '@/lib/utils'

export const isEonxEnvironment = () => {
  const cookies = parseCookies()
  const origin = (cookies[ORIGIN_COOKIE] || '').toLowerCase()
  return origin === 'eonx'
}

export const EonxEnvController = ({ children }: { children: ReactNode }) => {
  const [isEonxEnv, setEonxEnv] = useState(null as boolean | null)

  //
  // It was observed (via Amplitude events) that when running the app within The Pass App webview,
  // the isEonxEnv state is unexpectedly reset. The exact cause is unclear, but it may be related
  // to SSR hydration behavior or interference from the container app.
  //
  // Resetting the value back to what is stored in the session cookie resolves the issue.
  //
  // There is a separate issue where the session cookie sometimes gets cleared before the webview
  // session ends. Note that The Pass App keeps users logged in for extended periods (i.e., at
  // least several days). However, this issue is less common and will be handled separately with
  // cartValidationErrors.
  //
  // Alternatively, we could solve this by persisting the isEonxEnv state in query parameters, but
  // this approach has drawbacks (e.g., fragility with navigation) and would require more work.
  //
  useEffect(() => {
    const isOriginEonx = isEonxEnvironment()
    if (isOriginEonx !== isEonxEnv) {
      setEonxEnv(isOriginEonx)
    }
  }, [isEonxEnv])

  const enableEonx = () => {
    Cookies.set(ORIGIN_COOKIE, 'eonx')
    setEonxEnv(true)
  }

  const disableEonx = () => {
    Cookies.set(ORIGIN_COOKIE, 'none')
    setEonxEnv(false)
  }

  return (
    <EonxEnvContext.Provider value={{ enableEonx, disableEonx, isEonxEnv }}>
      {children}
    </EonxEnvContext.Provider>
  )
}

export * from '@/contexts/EonxEnvContext'

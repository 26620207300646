import 'whatwg-fetch'

import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import FlagProvider, {
  IConfig,
  useUnleashContext,
} from '@unleash/proxy-client-react'
import { observer } from 'mobx-react-lite'
import getConfig from 'next/config'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { InMemoryStorageProvider, UnleashClient } from 'unleash-proxy-client'
import { useQuery } from 'urql'

import { CurrentUserDocument } from '@/gql/graphql'
import { releaseStage } from '@/lib/config'
import { CustomerStoreContext } from '@/stores/CustomerStore'

const { publicRuntimeConfig } = getConfig()
const { REGION } = publicRuntimeConfig

export const UNLEASH_CONFIG: IConfig = {
  url: 'https://app.unleash-hosted.com/euaa002/api/frontend',
  clientKey: 'd14e2618cad473a8f23e328',
  appName: 'serve-frontend',
  environment: releaseStage,
}

const UpdateContext = observer(
  ({
    venueSlug,
    region,
  }: {
    venueSlug: string | null
    region: string | null
  }) => {
    const { sessionId } = useContext(CustomerStoreContext)
    const [{ data }] = useQuery({
      query: CurrentUserDocument,
      pause: isServer,
    })
    const updateContext = useUnleashContext()
    const userId = data?.currentUser?.id

    useEffect(() => {
      if (venueSlug) {
        void updateContext({
          sessionId,
          userId,
          properties: {
            venueSlug,
            ...(region ? { region } : {}),
          },
        })
      }
    }, [updateContext, venueSlug, region, sessionId, userId])

    return null
  },
)

export const UnleashContextProvider = observer(
  ({
    children,
    venueSlug,
  }: PropsWithChildren<{ venueSlug: string | null }>) => {
    const ssrOption =
      typeof window !== 'undefined'
        ? {}
        : { fetch: fetch, storageProvider: new InMemoryStorageProvider() }

    const client = new UnleashClient({
      ...UNLEASH_CONFIG,
      ...ssrOption,
      context: {
        properties: {
          // region specifies the frontend server region, not necessarily the venue region
          ...(REGION ? { region: REGION } : {}),
        },
      },
    })

    return (
      <FlagProvider unleashClient={client}>
        <UpdateContext venueSlug={venueSlug} region={REGION} />
        {children}
      </FlagProvider>
    )
  },
)

UnleashContextProvider.displayName = 'UnleashContextProvider'

import {
  getLocalStorage,
  setLocalStorage,
} from '@mr-yum/frontend-core/dist/support/env'
import { parseCookies } from 'nookies'
import qs from 'query-string'
import { v4 } from 'uuid'

import { config } from '@/lib/config'

interface CustomDataArgs {
  value?: number
  currency?: string
  contentName?: string
  contentCategory?: string
  contentType?: string
  contentIds?: string
  orderId?: string
  predictedLtv?: number
  numItems?: string
  searchString?: string
  status?: string
  deliveryCategory?: string
  contents?: { id: string; quantity: number }[]
}

interface UserDataArgs {
  externalId?: string
  clientIpAddress?: string // Client IP address
  clientUserAgent?: string // Client user agent
  fbc?: string // Click ID
  fbp?: string // Browser ID
  subscriptionId?: string // Subscription ID
  fbLoginId?: string // Facebook Login ID
  leadId?: string // Lead ID
}

interface BodyArgs {
  eventName?: string
  eventSourceUrl?: string
  eventId?: string
  slug: string
}

export const getOrCreateExternalId = (): string => {
  if (!getLocalStorage('sessionKey')) {
    setLocalStorage('sessionKey', v4())
  }

  return getLocalStorage('sessionKey') || v4()
}

type FacebookInputArgs = BodyArgs & UserDataArgs & CustomDataArgs

const conversionEvent = async (data: BodyArgs) => {
  const queryString = qs.stringify(data)

  await fetch(`${config.visitorsUrl}/api/v2/conversion?${queryString}`)
}

export const sendVisitorEvent = async (
  venueSlug: string,
  eventName?: string,
  customData?: CustomDataArgs,
) => {
  if (config.environment !== 'production') {
    return
  }

  try {
    const cookies = parseCookies() || {}
    const externalId = getOrCreateExternalId()

    const body: FacebookInputArgs = {
      eventName: eventName,
      clientUserAgent: navigator.userAgent,
      fbp: cookies._fbp,
      fbc: cookies._fbc,
      slug: venueSlug,
      externalId,
      eventSourceUrl: window.location.href,
      ...customData,
    }

    await conversionEvent(body)
  } catch (e) {
    // fail softly
  }
}
